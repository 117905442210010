<template>
  <div>
    <b-overlay
      :show="data.showLoader"
      rounded="sm"
      spinner-variant="primary"
      spinner-type="grow"
    >
      <b-card
        v-if="!data.showLoader && !data.showStats"
      >
        <h4>Awaiting insights ...</h4>

        Please check back once your Inclusion Index Survey data has been processed.
      </b-card>
      <b-card
        v-if="!data.showLoader && data.showStats"
        style="max-width: 1000px;"
      >
        <b-tabs
          pills
          justified
        >
          <b-tab active>
            <template #title>
              <span class="d-none d-sm-inline">Belongingness</span>
            </template>
            <insights-metric
              :data="data.belongingness"
            />
          </b-tab>

          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">Psychological Safety</span>
            </template>
            <insights-metric
              :data="data.psychologicalSafety"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">Organisational Trust</span>
            </template>
            <insights-metric
              :data="data.organisationalTrust"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">Engagement </span>
            </template>
            <insights-metric
              :data="data.engagement"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">Diversity Climate </span>
            </template>
            <insights-metric
              :data="data.diversityClimate"
            />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import {
  BCard, BTab, BTabs, BOverlay,
} from 'bootstrap-vue'
import axios from '@axios'
import InsightsMetric from './InsightsMetric.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BOverlay,

    InsightsMetric,

  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    this.data = {
      showLoader: true,
      showStats: true,
      insights: {
        text: 'insights',
      },
      actions: {
        text: 'actions',
      },
      belongingness: {
        radial: {
          colorA: '#34c672',
          colorB: '#6fc794',
          colorBG: '#d8f4e4',
          series: [0],
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
        },
        networkDensity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Density',
            measure: 'Friendship',
            hover: 'Network Density refers to the number of connections between employees within your network relative to the maximum number of connections that are possible, given the size of your network. Network Density provides an overview of the state of interconnectedness within your team when it comes to Friendship.',
          },
        },
        deCentralisation: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network De-centralisation',
            measure: 'Friendship',
            hover: 'We want our networks to be decentralised to ensure that information and advice is spread amongst our network. When information is not decentralised, and held by one or a few individuals, this can pose a problem for organisations. Central employees are information gatekeepers and their loss might be a huge disruption for the information flow.',
          },
        },
        networkIntegrity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Integrity',
            measure: 'Friendship',
            hover: ' We want our networks to be of high quality to ensure information can pass between people seamlessly. Poor network integrity can result in a vacuum or a “dead zone”, which means information has no way of travelling between subgroups of people. This is very problematic and can lead to echo chambers and feelings of isolation.',
          },
        },
        graph: {
          color: '#34c672',
          series: [
            {
              name: 'Belongingness',
              data: [2.8, 4.0, 3.6, 5.2, 3.8, 6.0, 5.5],
            },
          ],
        },
        card: {
          class: 'card-belongingness',
        },
        title: {
          class: 'text-b-green text-align-center',
          hover: 'Being accepted and included in the team',

        },
        name: 'Belongingness',
        text: {
          p1: 'As many as',
          percentage: '',
          p2: 'of employees report low or very low belongingness',
          title: 'Belongingness: I am part of the team.',
          body: '<p>When a workplace is inclusive, employees feel like they are a part of their team and their organisation. Belongingness is an interpersonal construct that refers to the connection and level of identification the employees have with their team. Employees who feel like they belong have been shown to be more engaged and productive. Most crucially, employees with higher levels of belonging report much better mental health than employees who feel excluded by their organisation or team.</p>',
          hoverGraphTitle: 'What is a Friendship Network?:',
        },
        items: [
        ],
        onaGroups: [
        ],
        onaGraphs: [
        ],
        onaStats: [
        ],
        breakdownList: [
        ],
        activeBreakdownName: '',

        activeOnaGroup: '',
        heatmap: {
        },
        lowestScores: '',
        isLowest: false,
      },
      psychologicalSafety: {
        radial: {
          colorA: '#f984d3',
          colorB: '#f6bae2',
          colorBG: '#fee6f6',
          series: [0],
          change: {
            icon: 'None', // 'ChevronDownIcon',
          },
        },
        networkDensity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Density',
            measure: 'Friction',
            hover: '',
          },
        },
        deCentralisation: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'De-centralisation',
            measure: 'Friction',
            hover: '',
          },
        },
        networkIntegrity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Integrity',
            measure: 'Friction',
            hover: '',
          },
        },
        graph: {
          color: '#f984d3',
          series: [
            {
              name: 'Psychological Safety',
              data: [2.8, 5.2, 3.8, 6, 5.5, 3.6, 4],
            },
          ],
        },
        card: {
          class: 'card-psychological-safety',
        },
        title: {
          class: 'text-b-pink text-align-center',
          hover: 'Having a social environment that is safe for risk-taking',
        },
        name: 'Psychological Safety',
        text: {
          p1: 'As many as',
          percentage: '',
          p2: 'of employees report low or very low psychological safety',
          title: 'Psychological safety: my voice matters.',
          body: '<p>One of the strongest predictors of productivity, psychological safety is about feeling comfortable to share your ideas and opinions - and confident that your voice will be heard.</p><p>Teams with low scores tend to be dominated by a small number of voices.  This risks missing out on valuable ideas and feedback from across the team, but also reduced productivity and engagement from those who feel they are not listened to, and therefore not valued.</p><p>Teams that score highly tend to make everyone feel welcome and included, free to share their opinions and confident that their ideas are heard and recognised.</p>',
          hoverGraphTitle: 'What is a Friction Network?:',

        },
        items: [
        ],
        onaGroups: [
        ],
        onaGraphs: [
        ],
        onaStats: [
        ],
        breakdownList: [
        ],
        activeBreakdownName: '',

        activeOnaGroup: '',
        heatmap: {
        },
        lowestScores: '',
        isLowest: false,
      },
      organisationalTrust: {
        radial: {
          colorA: '#1186ff',
          colorB: '#86bcf8',
          colorBG: '#ddedff',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        networkDensity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Density',
            measure: 'Trust',
            hover: '',
          },
        },
        deCentralisation: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'De-centralisation',
            measure: 'Trust',
            hover: '',
          },
        },
        networkIntegrity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Integrity',
            measure: 'Trust',
            hover: '',
          },
        },
        graph: {
          color: '#1186ff',
          series: [
            {
              name: 'Organisational Trust',
              data: [6, 3.8, 2.8, 3.6, 5.5, 4, 5.2],
            },
          ],
        },
        card: {
          class: 'card-organisational-trust',
        },
        title: {
          class: 'text-b-blue text-align-center',
          hover: 'Knowing the organisation as a fair player/good actor',

        },
        name: 'Organisational Trust',
        text: {
          p1: 'As many as',
          percentage: '',
          p2: 'of employees report low or very low trust in the organisation',
          title: 'Organisational Trust - an environment of  respect and understanding',
          body: '<p>We work best when we feel like we can not only rely on individual colleagues but on the organisation as a whole.</p><p>Through trust in our colleagues and our organisation we can build stronger relationships, be ourselves at work through knowing our organisation has our best interests at heart, and create a more collaborative and cohesive working environment by giving our colleagues the benefit of the doubt when it comes to mistakes and risks.</p>',
          hoverGraphTitle: 'What is a Trust Network?:',

        },
        items: [
        ],
        onaGroups: [
        ],
        onaGraphs: [
        ],
        onaStats: [
        ],
        breakdownList: [
        ],
        activeBreakdownName: '',

        activeOnaGroup: '',
        heatmap: {
        },
        lowestScores: '',
        isLowest: false,
      },
      engagement: {
        radial: {
          colorA: '#fc9531',
          colorB: '#f7c092',
          colorBG: '#ffead6',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        networkDensity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Density',
            measure: 'Collaboration',
          },
        },
        deCentralisation: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'De-centralisation',
            measure: 'Collaboration',
          },
        },
        networkIntegrity: {
          change: {
            icon: 'None', // 'ChevronUpIcon',
          },
          text: {
            title: 'Network Integrity',
            measure: 'Collaboration',
          },
        },
        graph: {
          color: '#fc9531',
          series: [
            {
              name: 'Engagement',
              data: [3.6, 6, 3.8, 5.5, 2.8, 4, 5.2],
            },
          ],
        },
        card: {
          class: 'card-engagement',
        },
        title: {
          class: 'text-b-orange text-align-center',
          hover: 'Feeling strong motivation to do get the job done',
        },
        name: 'Engagement',
        text: {
          p1: 'As many as',
          percentage: '',
          p2: 'of employees report low or very low engagement',
          title: 'A climate of motivation and commitment',
          body: '<p>Engagement is a state of intrinsic motivation characterised by feelings of vigor, dedication, and absorption. Engagement has been found to naturally bloom in climates of inclusiveness, characterised by high belongingness, psychological safety, organisational trust, and diversity climate.</p><p>When we have high engagement, we are committed and aligned with an organisations goals. Engagement is potentially the most studied (and consistent) predictor of positive outcomes such as low burnout rates,  low turnover, low absenteeism, and high productivity.</p>',
          hoverGraphTitle: 'What is a Collaboration Network?:',

        },
        items: [
        ],
        onaGroups: [
        ],
        onaGraphs: [
        ],
        onaStats: [
        ],
        breakdownList: [
        ],
        activeBreakdownName: '',

        activeOnaGroup: '',
        heatmap: {
        },
        lowestScores: '',
        isLowest: false,
      },
      diversityClimate: {
        radial: {
          colorA: '#7367f0',
          colorB: '#b1acf1',
          colorBG: '#ebe9fc',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        graph: {
          color: '#7367f0',
          series: [
            {
              name: 'Diversity Climate',
              data: [3.6, 5.5, 2.8, 4, 5.2, 3.8, 6],
            },
          ],
        },
        card: {
          class: 'card-diversity-climate',
        },
        title: {
          class: 'text-b-purple text-align-center',
          hover: 'Believing in the strength of diversity',
        },
        name: 'Diversity Climate',
        text: {
          p1: 'As many as',
          percentage: '',
          p2: 'of employees report low or very low diversity-climate at the organisation',
          title: 'Believing in the strength of our differences',
          body: '<p>The modern workplace is diverse, and diversity is a great strength! However, it also requires good management. When we talk about positive Diversity Climate, we talk about an environment where we feel that we will be valued and respected for who we are, as we are. </p><p>Low diversity climate can lead to higher wellbeing and lower turnover intentions among minority demographic groups within our organisation. However, everyone benefits from a positive diversity climate as research shows that it also boosts motivation and wellbeing among majority employees. </p>',

        },
        items: [
        ],
        onaGroups: [
        ],
        onaGraphs: [
        ],
        onaStats: [
        ],
        breakdownList: [
        ],
        activeBreakdownName: '',
        activeOnaGroup: '',
        heatmap: {
        },
        lowestScores: '',
        isLowest: false,
      },
      aequipScore: {
        radial: {
          colorA: '#FCBF1C',
          colorB: '#fadb8d',
          series: [0],
          change: {
            icon: 'None',
          },
        },
        graph: {
          color: '#FCBF1C',
          series: [
            {
              name: 'Flourishing Score',
              data: [4, 5.2, 6, 5.5, 2.8, 3.8, 3.6],
            },
          ],
        },
        card: {
          class: 'card-aequip-score',
        },
        title: {
          class: 'text-b-yellow text-align-center',
          hover: 'Our overall score based on the BIG 5',
        },
        name: 'Flourishing Score',
        text: {
          p1: 'This week',
          percentage: '',
          p2: 'of employees have replied to the survey',
        },
        items: [
        ],
        onaGroups: [
        ],
        onaGraphs: [
        ],
        onaStats: [
        ],
        breakdownList: [
        ],
        activeBreakdownName: '',

        activeOnaGroup: '',
        heatmap: {
        },
      },
    }
    this.getData()
  },
  methods: {
    getData() {
      // TODO refactor this to fetch from insights

      axios
        .get('/stats/insight')
        .then(response => {
          const insight = response.data[0]
          if (insight) {
            insight.data = JSON.parse(insight.data)
            if (!Object.prototype.hasOwnProperty.call(insight.data.bkpi, 'belongingness')) {
              this.data.showStats = false
              // TODO - data is pending - survey sent
            } else {
              this.data.showStats = true
              this.data.belongingness.radial.series = [insight.data.bkpi.belongingness * 10]
              this.data.belongingness.radial.series = [insight.data.bkpi.belongingness * 10]
              this.data.psychologicalSafety.radial.series = [insight.data.bkpi.psychologicalSafety * 10]
              this.data.organisationalTrust.radial.series = [insight.data.bkpi.organisationalTrust * 10]
              this.data.engagement.radial.series = [insight.data.bkpi.engagement * 10]
              this.data.diversityClimate.radial.series = [insight.data.bkpi.diversityClimate * 10]
              this.data.aequipScore.radial.series = [insight.data.bkpi.aequipScore * 10]

              // lowest scores

              const bkpiArray = [
                { id: 'belongingness', text: 'Belongingness', value: insight.data.bkpi.belongingness },
                { id: 'psychologicalSafety', text: 'Psychological Safety', value: insight.data.bkpi.psychologicalSafety },
                { id: 'organisationalTrust', text: 'Organisational Trust', value: insight.data.bkpi.organisationalTrust },
                { id: 'engagement', text: 'Engagement', value: insight.data.bkpi.engagement },
                { id: 'diversityClimate', text: 'Diversity Climate', value: insight.data.bkpi.diversityClimate }]
              bkpiArray.sort((a, b) => ((a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))) /* eslint-disable-line no-nested-ternary */
              console.log(bkpiArray)
              const lowestString = `${bkpiArray[0].text} and ${bkpiArray[1].text}`
              this.data.belongingness.lowestScores = lowestString
              this.data.psychologicalSafety.lowestScores = lowestString
              this.data.organisationalTrust.lowestScores = lowestString
              this.data.engagement.lowestScores = lowestString
              this.data.diversityClimate.lowestScores = lowestString
              this.data[bkpiArray[0].id].isLowest = true
              this.data[bkpiArray[1].id].isLowest = true

              this.data.belongingness.text.percentage = insight.data.bkpi.histogram.percentageLowBelongingness
              this.data.psychologicalSafety.text.percentage = insight.data.bkpi.histogram.percentageLowPsychologicalSafety
              this.data.organisationalTrust.text.percentage = insight.data.bkpi.histogram.percentageLowOrganisationalTrust
              this.data.engagement.text.percentage = insight.data.bkpi.histogram.percentageLowEngagement
              this.data.diversityClimate.text.percentage = insight.data.bkpi.histogram.percentageLowDiversityClimate
              this.data.aequipScore.text.percentage = insight.data.bkpi.histogram.percentageLowAequipScore

              Object.entries(insight.data).forEach(([behaviour, behaviourData]) => {
                if (behaviour !== 'bkpi') {
                  if (behaviourData !== null) {
                    Object.entries(behaviourData).forEach(([key, value]) => {
                      if (key === 'onaGraphs') {
                        this.data[behaviour].onaGraphs = behaviourData.onaGraphs
                        if (behaviourData.onaGraphs) {
                          const groupsList = []
                          Object.entries(behaviourData.onaGraphs).forEach(([group]) => {
                            groupsList.push({
                              text: group,
                              value: group,
                            })
                          })
                          groupsList.sort()
                          const firstValue = 0
                          this.data[behaviour].activeOnaGroup = groupsList[firstValue].value
                          this.data[behaviour].onaGroups = groupsList
                        }
                      } else if (key === 'onaStats') {
                        this.data[behaviour].onaStats = behaviourData.onaStats
                      } else if (key === 'breakdown') {
                        const metricLookup = {}
                        this.data[behaviour].breakdownList = []
                        Object.entries(value).forEach(([breakdownName, breakdownData]) => {
                          this.data[behaviour].breakdownList.push({
                            text: breakdownName,
                            value: breakdownName,
                          })
                          this.data[behaviour].heatmap[breakdownName] = {
                            groupNames: [
                            ],
                            rows: [
                            ],
                          }
                          metricLookup[breakdownName] = {}
                          Object.entries(breakdownData).forEach(([groupName, groupData]) => {
                            if (groupName.length > 20) {
                              const groupNameWords = groupName.split(' ')
                              const newGroupNameArray = []
                              let section = ''
                              let sectionLength = 0
                              groupNameWords.forEach(word => {
                                sectionLength += word.length
                                if (sectionLength > 20) {
                                  newGroupNameArray.push(section)
                                  sectionLength = word.length
                                  section = word
                                } else {
                                  section += ` ${word}`
                                }
                              })
                              newGroupNameArray.push(section)
                              this.data[behaviour].heatmap[breakdownName].groupNames.push(newGroupNameArray)
                            } else {
                              this.data[behaviour].heatmap[breakdownName].groupNames.push(groupName)
                            }

                            Object.entries(groupData).forEach(([metricName, metricData]) => { // eslint-disable-line no-unused-vars
                              if (!Object.prototype.hasOwnProperty.call(metricLookup[breakdownName], metricData.behaviour)) {
                                metricLookup[breakdownName][metricData.behaviour] = []
                              }
                              metricLookup[breakdownName][metricData.behaviour].push(Math.round(metricData.average * 10) / 10)
                            })
                          })

                          Object.entries(metricLookup[breakdownName]).forEach(([metricName, metricData]) => {
                            this.data[behaviour].heatmap[breakdownName].rows.push({
                              name: metricName,
                              data: metricData,
                            })
                          })
                          this.data[behaviour].heatmap[breakdownName].rows.reverse()
                        })
                        console.log(this.data)
                        const firstValue = 0
                        this.data[behaviour].activeBreakdownName = Object.keys(this.data[behaviour].heatmap)[firstValue]
                      // do group stats stuff
                      } else if (value !== null) {
                        this.data[behaviour].items.push({
                          behavioural_indicators: value.behaviour,
                          description: value.description,
                          people_categorised: value.underThreshold,
                        })
                      }
                    })
                  }
                }
              })
            }
          } else {
            this.data.showStats = false
            // TODO - data is pending - survey not sent
          }
          this.data.showLoader = false
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
